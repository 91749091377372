import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import PromptPopupModel from '../../Models/PromptPopupModel.interface';
import { useRef } from 'react';
import XHtmlArea from '../../DesignComponents/Molecules/XHtmlArea/XHtmlArea';
import ButtonLoading from '../ButtonLoading';

interface Props {
  model: PromptPopupModel | undefined;
  loading?: boolean;
  onClose?: () => void;
  onAction?: () => void;
}

export default function DefaultModal({ model, onClose, onAction, loading }: Props) {

  const show = !!model;

  const prevModel = useRef(model);
  prevModel.current = model ?? prevModel.current;
  const data = prevModel.current;

  return (
    <Dialog open={show} onClose={onClose}>
      <>
        {
          data && (
            <>
              <DialogTitle>{data.heading}</DialogTitle>
              <DialogContent>
                <XHtmlArea content={data.bodyText} />
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>{data.cancelButtonText}</Button>
                <Button variant="contained" onClick={onAction} autoFocus>
                  <ButtonLoading loading={!!loading}>
                    {data.actionButtonText}
                  </ButtonLoading>
                </Button>
              </DialogActions>
            </>
          )
        }
      </>
    </Dialog>
  );
}