import { GetCart } from 'Commerce/Cart/Cart';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CheckoutHeader from './Components/CheckoutHeader/CheckoutHeader';
import CheckoutPaymentModel from 'Models/Checkout/Payment/CheckoutPaymentModel.interface';
import CheckoutPaymentStatusEnum from 'Models/Checkout/Payment/CheckoutPaymentStatusEnum.interface';
import { PaymentWindow } from './Components/PaymentWindow/PaymentWindow';
import { CheckoutForm } from './Components/CheckoutForm/CheckoutForm';
import { useAppSettings, useTranslations } from '../../../context/init-data.context';
import { useRouter } from '../../../lib/router/router-hook';
import { Alert, AlertTitle } from '@mui/material';

interface PaymentError {
  heading?: string;
  message?: string;
  htmlMessage?: string;
}

function CheckoutPage() {

  const {
    checkoutLabels: { goToPreviousPage }
  } = useTranslations();

  const { languageRoute } = useAppSettings();
  const { cart, isCartEmpty } = GetCart(languageRoute);
  const router = useRouter();

  const [paymentError, setPaymentError] = useState<PaymentError | undefined>(() => {
    const error = router.query.get("error");
    if (!error) return undefined;
    return {message: error};
  });

  const [payment, setPayment] = useState<CheckoutPaymentModel>();

  const backClick = useMemo(() => {
    // if (payment) return;
    if (payment) return () => router.reload();
    return () => router.goBack();
  }, [payment]);

  const onPayment = useCallback((data: CheckoutPaymentModel) => {
    if (data.status == CheckoutPaymentStatusEnum.Failed) {
      setPaymentError({heading: data.paymentErrorHeading, htmlMessage: data.paymentErrorDescription});
      setPayment(undefined)
      return;
    }

    if (data.redirectUrl) {
      router.navigateByStr(data.redirectUrl);
      return;
    }

    setPayment(data);
    setPaymentError(undefined);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [languageRoute]);

  useEffect(() => {
    if (!cart || !isCartEmpty || payment) return;
    router.navigate([]).then();
  }, [cart, isCartEmpty, payment]);

  const failedPayment = useCallback((heading: string, message: string) => {
    setPaymentError({heading, message});
  }, []);

  return (
    <>
      <CheckoutHeader backLabel={goToPreviousPage} onBack={backClick} />
      {
        paymentError &&
        <Alert severity="warning" style={{maxWidth: 800, margin: '20px auto 0 auto'}}>
          {paymentError.heading && <AlertTitle>{paymentError.heading}</AlertTitle>}
          {paymentError.htmlMessage && <div dangerouslySetInnerHTML={{__html: paymentError.htmlMessage}}></div>}
          {paymentError.message}
        </Alert>
      }
      {payment ? <PaymentWindow payment={payment} onFailed={failedPayment} /> : <CheckoutForm onPayment={onPayment} />}
    </>
  );
}

export default CheckoutPage;
