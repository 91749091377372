import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import { useCallback, useState } from 'react';
import TermsAndConditions from '../../TermsAndConditions/TermsAndConditions';
import { GetCart } from 'Commerce/Cart/Cart';
import CheckoutPageModel from '../../../../../../Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import { Button } from '@mui/material';
import { ISubmitEmitter, useSubmitEmitter } from '../../../../../../lib/util/submit-emitter';
import { ValidationErrorModel } from '../../../../../../lib/requests/request-error';
import UserAgreementsModel from '../../../../../../Models/Checkout/CustomerDetails/UserAgreementsModel.interface';
import ButtonLoading from '../../../../../../components/ButtonLoading';
import { useAppSettings, useTranslations } from '../../../../../../context/init-data.context';
import { usePageData } from '../../../../../../context/page-data.context';

type Props = {
  onSave: () => void;
  submitEmitter: ISubmitEmitter<UserAgreementsModel>;
  errors?: ValidationErrorModel;
  active: boolean;
  valid: boolean;
  loading: boolean;
};

const ConfirmationStep = ({ onSave, active, loading, submitEmitter, valid }: Props) => {
  const { languageRoute } = useAppSettings();
  const { cart } = GetCart(languageRoute);
  const { newsletterSignupText } = usePageData<CheckoutPageModel>();

  const {
    checkoutLabels: { goToPayment, iAccept, pay, termsAndConditionsCtaText }
  } = useTranslations();

  const [checked, setChecked] = useState(false);
  const [newsletter, setNewsletter] = useState(true);

  const nextActionLabel =
    cart?.totalPriceLeftToPay?.price === 0 ? pay : goToPayment;

  const submit = useCallback(() => ({
    subscribeToNewsletter: newsletter,
    termsAccepted: checked
  }), [newsletter, checked]);

  useSubmitEmitter(submitEmitter, submit, checked);

  return (
    <div>
      {
        newsletterSignupText &&
        <Checkbox css={{ marginTop: 16 }} isChecked={newsletter} onCheck={() => setNewsletter((prev) => !prev)}
                  labelCss={{ alignItems: 'flex-start' }}>
          {() => <div dangerouslySetInnerHTML={{ __html: newsletterSignupText }}></div>}
        </Checkbox>
      }
      <Checkbox css={{ marginTop: 16 }} button isChecked={checked} onCheck={() => setChecked((prev) => !prev)}>
        {() => <>{iAccept + ' '}&nbsp; <TermsAndConditions text={termsAndConditionsCtaText} />.</>}
      </Checkbox>

      {active &&
        <Button style={{ marginTop: 16 }} variant="contained" onClick={onSave} disabled={!checked || !valid}>
          <ButtonLoading loading={loading}>
            {nextActionLabel}
          </ButtonLoading>
        </Button>
      }
    </div>
  );
};

export default ConfirmationStep;
