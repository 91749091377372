import { ZodForm } from '../../../../../../lib/forms/zod-form';
import CheckoutPageModel from '../../../../../../Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import styles from './DeliveryAddressForm.module.scss';
import { TextInput } from '../../../../../../lib/inputs/TextInput';
import { FormSelectInput } from '../../../../../../lib/inputs/FormSelectInput';
import { DeliveryAddressData } from './delivery.models';
import AddressInput from '../../../../../../components/AddressInput/AddressInput';
import { useCallback, useEffect, useRef, useState } from 'react';
import { setShippingCountry, switchShippingCountry } from '../../../checkout.requests';
import { FetchCartAndNotifyAll } from '../../../../../Cart/Cart';
import PromptPopupModel from '../../../../../../Models/PromptPopupModel.interface';
import DefaultModal from '../../../../../../components/DefaultModal/DefaultModal';
import { useRequestState } from '../../../../../../lib/requests/use-request-state';
import { AddressValue } from '../../../../../../components/AddressInput/AddressInput.models';
import { useWatch } from 'react-hook-form';
import { useAppSettings, useTranslations } from '../../../../../../context/init-data.context';
import { usePageData } from '../../../../../../context/page-data.context';
import { useHttpClient } from '../../../../../../lib/requests/HttpClientProvider';

export function DeliveryAddressForm({ form, isShipping }: {
  isShipping?: boolean
  form: ZodForm<DeliveryAddressData>
}) {

  const autofillPrefix = isShipping ? 'shipping' : 'billing';

  const {
    checkoutLabels: {
      address,
      country,
      city,
      zipCode,
      firstName,
      lastName,
      infoLabel
    }
  } = useTranslations();

  const { languageRoute } = useAppSettings();
  const { shippingDestinations } = usePageData<CheckoutPageModel>();

  const currentCountry = useWatch({control: form.control, name: 'country'});
  const strictAddress = currentCountry === 'DK';
  const autoCompleteAddress = currentCountry === 'DK' || currentCountry === 'SE';

  const setAddressData = useCallback((value: AddressValue|undefined)=> {
    if (!strictAddress && !value) return;
    form.setValue('city', value?.city ?? '');
    form.setValue('postalCode', value?.postcode ?? '', {shouldValidate: true});
  }, [strictAddress]);

  //<editor-fold desc="Country Handling">
  const prevCountry = useRef<string>(currentCountry);
  const [countryModal, setCountryModal] = useState<PromptPopupModel | undefined>(undefined);

  const client = useHttpClient();
  const updateCountry = useCallback(async () => {
    try {
      const result = await switchShippingCountry(client, currentCountry);

      if (result.requiresCustomerConfirmation) {
        setCountryModal(result.confirmation);
        return;
      }

      await FetchCartAndNotifyAll(languageRoute);
      prevCountry.current = currentCountry;

    } catch {
      form.setValue('country', prevCountry.current);
    }
  }, [currentCountry]);

  useEffect(() => {
    if (currentCountry === prevCountry.current) return;
    form.customReset();

    if (!isShipping) {
      prevCountry.current = currentCountry;
      return;
    }

    updateCountry().then();
  }, [currentCountry]);

  const revertCountry = useCallback(() => {
    setCountryModal(undefined);
    form.setValue('country', prevCountry.current);
  }, []);

  const [confirmingCountry, setConfirmingCountry] = useRequestState();

  const confirmCountry = useCallback(async () => {
    try {
      const req = setShippingCountry(client, currentCountry);
      setConfirmingCountry(req);

      await req;

      await FetchCartAndNotifyAll(languageRoute);

      setCountryModal(undefined);
      prevCountry.current = currentCountry;
    } catch {
      form.setValue('country', prevCountry.current);
    }
  }, [currentCountry]);
  //</editor-fold>

  return (
    <>
      <div className={styles.inputWrapper}>
        <TextInput form={form} name="firstName" label={firstName} autoComplete={autofillPrefix + ' given-name'} />
        <TextInput form={form} name="lastName" label={lastName} autoComplete={autofillPrefix + ' family-name'} />
      </div>

      <FormSelectInput form={form} name="country" label={country} options={shippingDestinations}
                       clearable={false}
                       getKey={x => x.code} getOption={x => x.name} />

      {
        autoCompleteAddress ? (
          <AddressInput form={form} countryCode={currentCountry} textName="address" dataName="addressData" label={address}
                        autoComplete={autofillPrefix + 'street-address'} onValueChange={setAddressData}/>
        ) : (
          <TextInput form={form} name="address" label={address} autoComplete={autofillPrefix + 'street-address'} />
        )
      }

      {
        !strictAddress &&
        <TextInput form={form} name="info" label={infoLabel} autoComplete={autofillPrefix + ' info'} />
      }

      <div className={`${styles.inputWrapper} ${styles.postal}`}>
        <TextInput form={form} name="postalCode" readonly={strictAddress} label={zipCode}
                   autoComplete={autofillPrefix + ' postal-code'} />
        <TextInput form={form} name="city" readonly={strictAddress} label={city}
                   autoComplete={autofillPrefix + ' address-level2'} />
      </div>

      <DefaultModal model={countryModal} onClose={revertCountry} onAction={confirmCountry} loading={confirmingCountry.loading} />
    </>
  );
}