
type Props = {
  src: string;
  height?: string;
};

export default function Iframe({ src, height }: Props) {

  return (
      <iframe
        style={{minHeight: height, width: '100%', flex: 1}}
        title="payment gateway"
        src={src}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
  );
}
