import TermsAndConditionsModel from 'Models/TermsAndConditions/TermsAndConditionsModel.interface';
import SideDialog from '../../../../../lib/side-dialog/SideDialog';
import { MouseEvent, useCallback, useState } from 'react';
import { HttpClient } from '../../../../../lib/requests/http-client';
import { useRequestState } from '../../../../../lib/requests/use-request-state';
import { CircularProgress } from '@mui/material';
import XHtmlArea from '../../../../../DesignComponents/Molecules/XHtmlArea/XHtmlArea';
import { RequestState } from '../../../../../lib/requests/request-state';
import { useHttpClient } from '../../../../../lib/requests/HttpClientProvider';

type TermsAndConditionsProps = {
  termsAndConditions?: TermsAndConditionsModel,
  text: string
};

function loadToc(client: HttpClient) {
  return client.get(`api://checkout/GetTermsAndConditions`)
    .go<TermsAndConditionsModel>();
}

function TermsAndConditions({
  termsAndConditions,
  text
}: TermsAndConditionsProps) {

  const [open, setOpen] = useState(false);
  const [request, setRequest] = useRequestState<TermsAndConditionsModel>();
  const client = useHttpClient();

  const onClick = useCallback((e: MouseEvent) => {
    e.preventDefault();
    setOpen(true);
    if (termsAndConditions) setRequest(new RequestState(Promise.resolve(termsAndConditions)))
    else setRequest(loadToc(client));
  }, []);


  return (
    <>
      <a style={{color: 'var(--colors-emphasisedLinkColor)', fontWeight: 500}} onClick={onClick}>
        {text}
      </a>

      <SideDialog open={open} onClose={() => setOpen(false)} header={request.result?.header}>
        {request.loading && <CircularProgress style={{margin: 'auto'}} />}
        {request.result?.body && <XHtmlArea css={{lineHeight: 1.5}} content={request.result.body}/>}
      </SideDialog>
    </>
  );
}

export default TermsAndConditions;
