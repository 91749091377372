import { useEffect, useState } from 'react';

interface MountState {
  unmounted: boolean;
  mounted: boolean;
}

export function useMounted(): MountState {
  const [state] = useState({unmounted: false, mounted: false});
  useEffect(() => {
    state.mounted = true;
    return () => {
      state.unmounted = true;
    };
  }, []);
  return state;
}