import styles from './DeliveryStep.module.scss';
import { GetShippingDestination } from '../../../../../CountryPicker/ShippingDestination';
import Checkbox from '../../../../../../DesignComponents/Atoms/Input/Checkbox';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import Heading from '../../../../../../DesignSystem/Typography/Headings/Heading';
import { DeliveryAddressForm } from './DeliveryAddressForm';
import { useAddressForm } from './delivery-form';
import CheckoutPageModel from '../../../../../../Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import { DeliveryAddressData } from './delivery.models';
import OrderAddressModel from '../../../../../../Models/Shared/OrderAddressModel.interface';
import { ValidationErrorModel } from '../../../../../../lib/requests/request-error';
import { camelCase } from '@juulsgaard/ts-tools';
import { z, ZodError } from 'zod';
import { ZodForm } from '../../../../../../lib/forms/zod-form';
import ButtonLoading from '../../../../../../components/ButtonLoading';
import { ISubmitEmitter, useSubmitEmitter } from '../../../../../../lib/util/submit-emitter';
import ShippingAndBillingAddressModel
  from '../../../../../../Models/Checkout/CustomerDetails/ShippingAndBillingAddressModel.interface';
import { useTranslations } from '../../../../../../context/init-data.context';
import { usePageData } from '../../../../../../context/page-data.context';

export const MAX_NAME_LENGTH = 40;
export const MAX_LENGTH_ZIP_CODE = 10;

function mapAddress(address: DeliveryAddressData): OrderAddressModel {
  return {
    firstName: address.firstName,
    lastName: address.lastName,
    country: address.country,
    city: address.city,
    postalCode: address.postalCode,
    address: address.address,
    info: address.info ?? '',
    street: address.addressData?.street ?? '',
    streetNumber: address.addressData?.streetNumber ?? '',
    letter: address.addressData?.letter ?? '',
    floor: address.addressData?.floor ?? '',
    door: address.addressData?.door ?? ''
  } satisfies OrderAddressModel;
}

function useAddressValidation() {

  const {
    validationLabels: {
      fillInMinLengthZipCode,
      exceededMaxLengthZipCode,
      expectedNumberForZipCode
    },
    errorLabels: {
      validationError
    }
  } = useTranslations();

  const dkValidation = useMemo(() => z.object({
    postalCode: z.string().trim().regex(/^\d+$/, expectedNumberForZipCode)
  }), []);

  const seValidation = useMemo(() => z.object({
    postalCode: z.string().trim().regex(/^\d+$/, expectedNumberForZipCode).max(5, exceededMaxLengthZipCode).min(
      5,
      fillInMinLengthZipCode
    )
  }), []);

  return useCallback((address: DeliveryAddressData, form: ZodForm<DeliveryAddressData>) => {
    try {
      switch (address.country.toLowerCase()) {
        case 'dk':
          dkValidation.parse(address);
          break;
        case 'se':
        case 'fi':
          seValidation.parse(address);
          break;
      }
    } catch (e) {
      if (!(e instanceof ZodError)) {
        throw e;
      }
      for (const error of e.errors) {
        form.setError(error.path.join('.') as any, error);
      }
      throw new Error(e.errors.length === 1 ? e.errors[0].message : validationError);
    }
  }, []);
}

interface Props {
  onSave: () => void;
  submitEmitter: ISubmitEmitter<ShippingAndBillingAddressModel>;
  errors?: ValidationErrorModel;
  active: boolean;
  valid: boolean;
  loading: boolean;
}

export default function DeliveryStep({ onSave, submitEmitter, errors, active, loading, valid }: Props) {

  const {
    checkoutLabels: { billingAddressSameAsDeliveryAddress, goToTermsAndConditions, billingAddress }
  } = useTranslations();

  //<editor-fold desc="Setup Forms">
  const currentShippingDestination = GetShippingDestination();

  const {
    checkoutFormDataModel: { shippingAndBillingAddressModel: prevData }
  } = usePageData<CheckoutPageModel>();
  console.log(currentShippingDestination);
  const shippingForm = useAddressForm(prevData?.shipping, currentShippingDestination.code, true);
  const billingForm = useAddressForm(prevData?.billing, currentShippingDestination.code);
  //</editor-fold>

  const [sameAddress, setSameAddress] = useState(prevData?.billingIsSameAsShipping ?? true);

  const formIsValid = shippingForm.formState.isValid && (
    sameAddress || billingForm.formState.isValid
  );

  const validateAddress = useAddressValidation();

  useEffect(() => {
    if (!errors) return;

    // Apply server validations errors to form when possible
    for (const { property, error } of errors.errors) {
      const segments = property.split('.');
      if (segments.length < 2) continue;
      const form = segments.at(-2) === 'Billing' ? billingForm : shippingForm;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      form.setError(camelCase(segments.at(-1)!) as any, { message: error, type: 'validate' });
    }
  }, [errors]);

  const submit = useCallback(() => {
    shippingForm.handleSubmit(() => undefined);
    if (!sameAddress) billingForm.handleSubmit(() => undefined);
    if (!formIsValid) throw new Error("Invalid Form");
    const shipping = shippingForm.getFormValue();
    const billing = billingForm.getFormValue();

    validateAddress(shipping, shippingForm);
    if (!sameAddress) validateAddress(billing, billingForm);

    return {
      shipping: mapAddress(shipping),
      billingIsSameAsShipping: sameAddress,
      billing: mapAddress(billing)
    };
  }, [shippingForm, billingForm, sameAddress]);

  useSubmitEmitter(submitEmitter, submit, formIsValid);

  return (
    <form className={styles.form} onSubmit={onSave}>

      <DeliveryAddressForm isShipping form={shippingForm} />

      <Checkbox css={{ marginTop: 16 }}
                text={billingAddressSameAsDeliveryAddress}
                isChecked={sameAddress}
                onCheck={(e) => setSameAddress(e.target.checked)}
      />

      {!sameAddress && (
        <>
          <Heading tag="h3" size="xs" weights="semiBold" css={{ marginTop: 16 }}>
            {billingAddress}
          </Heading>
          <DeliveryAddressForm form={billingForm} />
        </>
      )}

      {active &&
        <Button style={{ marginTop: 16 }} type="button" variant="contained" disabled={!formIsValid || !valid}
                onClick={onSave}>
          <ButtonLoading loading={loading}>
            {goToTermsAndConditions}
          </ButtonLoading>
        </Button>
      }
    </form>
  );
}