import { DeliveryAddressData } from './delivery.models';
import { useZodForm, ZodForm } from '../../../../../../lib/forms/zod-form';
import { z } from 'zod';
import { MAX_LENGTH_ZIP_CODE, MAX_NAME_LENGTH } from './DeliveryStep';
import OrderAddressModel from '../../../../../../Models/Shared/OrderAddressModel.interface';
import { AddressValue, zAddressValue } from '../../../../../../components/AddressInput/AddressInput.models';

import { useTranslations } from '../../../../../../context/init-data.context';

function mapAddress(address: OrderAddressModel): Partial<DeliveryAddressData> {

  const data: Partial<AddressValue> = {
    street: address.street || undefined,
    streetNumber: address.streetNumber || undefined,
    letter: address.letter || undefined,
    floor: address.floor || undefined,
    door: address.door || undefined,
    postcode: address.postalCode || undefined,
    city: address.city || undefined
  };

  return {
    firstName: address.firstName || '',
    lastName: address.lastName || '',
    address: address.address || '',
    country: address.country || '',
    city: address.city || '',
    postalCode: address.postalCode || '',
    info: address.info || '',
    addressData: zAddressValue.safeParse(data).success ? data as AddressValue : null
  }
}

export function useAddressForm(defaultAddress: OrderAddressModel|undefined, countryCode: string|undefined, isShipping = false): ZodForm<DeliveryAddressData> {
  const {
    validationLabels: {
      fillInFirstName,
      exceededMaxLengthFirstName,
      fillInLastName,
      exceededMaxLengthLastName,
      fillInZipCode,
      exceededMaxLengthZipCode,
      fillInCity,
      fillInAddress,
      fillInCountry
    }
  } = useTranslations();

  return useZodForm(
    {
      firstName: z.string()
        .min(1, fillInFirstName)
        .max(MAX_NAME_LENGTH, { message: exceededMaxLengthFirstName }),
      lastName: z
        .string()
        .min(1, fillInLastName)
        .max(MAX_NAME_LENGTH, { message: exceededMaxLengthLastName }),
      country: z.string().min(1, fillInCountry),
      address: z.string().min(1, fillInAddress),
      addressData: zAddressValue.nullable(),
      info: z.string().optional(),
      postalCode: z.string()
        .min(4, { message: fillInZipCode })
        .max(MAX_LENGTH_ZIP_CODE, { message: exceededMaxLengthZipCode }),
      city: z.string().min(1, fillInCity),
    },
    {
      initialValues: () => {
        if (!defaultAddress?.country) return {country: countryCode};
        if (isShipping && defaultAddress.country !== countryCode) return {country: countryCode};
        return mapAddress(defaultAddress)
      },
      resetValues: {country: undefined, firstName: undefined, lastName: undefined}
    }
  );
}