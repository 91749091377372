import styles from './CheckoutSection.module.scss';
import { ReactNode } from 'react';

interface Props {
  heading: string;
  step: number;
  currentStep: number;
  children: ReactNode;
}

export function CheckoutSection({ heading, step, currentStep, children }: Props) {
  return (
    <section className={styles.step}>
      <h2>{step}. {heading}</h2>
      {currentStep >= step &&
        <div className={styles.stepContent}>
          {children}
        </div>
      }
    </section>
  );
}