import { useCallback, useEffect, useMemo, useState } from 'react';
import { mapObj } from '@juulsgaard/ts-tools';

export interface ISubmitEmitter<T> {
  setValid(isValid: boolean): void;
  register(callback: (() => T)|undefined): void;
}

export class SubmitEmitter<T> implements ISubmitEmitter<T> {
  private _callback?: () => T;
  public valid = false;
  get registered() {return !!this._callback}

  public onValidChange?: (valid: boolean) => void;
  public onRegisteredChange?: (valid: boolean) => void;

  public getValue() {
    return this._callback?.();
  }

  public register(callback: (() => T)|undefined): void {
    const wasRegistered = this.registered;
    this._callback = callback;
    if (wasRegistered !== this.registered) {
      this.onRegisteredChange?.(this.registered);
    }
  }

  setValid(isValid: boolean) {
    const wasValid = this.valid;
    this.valid = isValid;

    if (wasValid !== this.valid) {
      this.onValidChange?.(this.valid);
    }
  }
}

type UnwrapPartial<T extends Record<string, SubmitEmitter<unknown>>> = {
  [K in keyof T]?: T[K] extends SubmitEmitter<infer U> ? U : never
}

type Unwrap<T extends Record<string, SubmitEmitter<unknown>>> = {
  [K in keyof T]: T[K] extends SubmitEmitter<infer U> ? U : never
}


export interface UseEmittersValue<T extends Record<string, SubmitEmitter<unknown>>> {
  emitters: T;
  valid: boolean;
  getValue(): UnwrapPartial<T>|undefined;
  getFullValue(): Unwrap<T>|undefined;
}

export function useEmitters<T extends Record<string, SubmitEmitter<unknown>>>(init: () => T): UseEmittersValue<T> {
  const [emitters] = useState(init);
  const [validity, setValidity] = useState<Record<string, boolean>>(
    () => mapObj(emitters, x => x.valid)
  );
  const [registered, setRegistered] = useState<Record<string, boolean>>(
    () => mapObj(emitters, x => x.registered)
  );

  useEffect(() => {
    for (const key in emitters) {
      emitters[key].onRegisteredChange = reg => setRegistered(x => ({...x, [key]: reg}));
      emitters[key].onValidChange = valid => setValidity(x => ({...x, [key]: valid}));
    }
  }, []);

  const valid = useMemo(() => {
    for (const key in emitters) {
      if (registered[key] && !validity[key]) return false;
    }
    return true;
  }, [emitters, validity, registered]);

  const getValue = useCallback(
    () => {
      if (!valid) return undefined;
      try {
        return mapObj(emitters, x => x.getValue()) as UnwrapPartial<T>
      } catch(e) {
        console.warn('Failed to get forms values', e);
        return undefined;
      }
    },
    [emitters, valid]
  );

  const getFullValue = useCallback(
    () => {
      if (!valid) return undefined;
      const values = {} as Record<string, unknown>;

      try {
        for (const key in emitters) {
          const emitter = emitters[key];
          if (!emitter.registered) return undefined;
          values[key] = emitters[key].getValue();
        }

        return values as Unwrap<T>;

      } catch(e) {
        console.warn('Failed to get forms values', e);
        return undefined;
      }
    },
    [emitters, valid]
  );

  return {
    emitters,
    valid,
    getValue,
    getFullValue
  }
}

export function useSubmitEmitter<T>(emitter: ISubmitEmitter<T>, submit: () => T, isValid: boolean) {
  useEffect(() => emitter.register(submit), [emitter, submit]);
  useEffect(() => () => emitter.register(undefined), []);

  useEffect(() => emitter.setValid(isValid), [emitter, isValid]);
}