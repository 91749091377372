import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

export default function ButtonLoading({loading, children}: {loading: boolean, children: ReactNode}) {
  if (!loading) return <span className="text">{children}</span>;
  return (
    <>
      <CircularProgress style={{position: 'absolute', top: '50%', left: '50%', translate: '-50% -50%'}} className="button-loading" color="inherit" size={24} />
      <span className="text" style={{opacity: 0.4}}>{children}</span>
    </>
  )
}