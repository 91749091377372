import { IconButton, Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import { ReactNode } from 'react';
import style from './SideDialog.module.scss';

interface Props {
  open?: boolean;
  onClose?: () => void;
  header?: string;
  children: ReactNode;
}

export default function SideDialog({open, onClose, header, children}: Props) {

  return (
    <Modal open={!!open} onClose={onClose}>
      <div className={style.modal}>
        <div className={style.header}>
          <h1>{header}</h1>
          <IconButton aria-label="close" onClick={onClose}>
            <Close/>
          </IconButton>
        </div>
        <div className={style.content}>
          {children}
        </div>
      </div>
    </Modal>
  )
}