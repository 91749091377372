import { z } from 'zod';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import { ValidationErrorModel } from '../../../../../../lib/requests/request-error';
import { Button } from '@mui/material';
import { useZodForm, ZodForm } from '../../../../../../lib/forms/zod-form';
import { TextInput } from '../../../../../../lib/inputs/TextInput';
import { DateInput } from '../../../../../../lib/inputs/DateInput';
import { camelCase } from '@juulsgaard/ts-tools';
import { useCallback, useEffect } from 'react';
import ButtonLoading from '../../../../../../components/ButtonLoading';
import { ISubmitEmitter, useSubmitEmitter } from '../../../../../../lib/util/submit-emitter';
import ContactDetailsModel from '../../../../../../Models/Checkout/CustomerDetails/ContactDetailsModel.interface';
import { useTranslations } from '../../../../../../context/init-data.context';
import { usePageData } from '../../../../../../context/page-data.context';

interface CustomerDetails {
  email: string;
  phoneNumber?: string;
  birthDate: Date | null;
}

const minDate = new Date();
minDate.setFullYear(minDate.getFullYear() - 18);
minDate.setHours(0, 0, 0, 0);

function createDate(value: string | undefined) {
  if (!value) return null;
  const date = new Date(value);
  if (isNaN(date.getTime())) return null;
  return date;
}

interface Props {
  onSave: () => void;
  submitEmitter: ISubmitEmitter<ContactDetailsModel>;
  errors?: ValidationErrorModel;
  active: boolean;
  valid: boolean;
  loading: boolean;
}

export default function CustomerDetailsStep({ onSave, active, loading, submitEmitter, errors, valid }: Props) {

  const {
    checkoutFormDataModel: { contactDetailsModel },
    isBirthDateInputEnabled
  } = usePageData<CheckoutPageModel>();

  const {
    checkoutLabels: { email, phoneNumber, birthDate, goToDeliveryAddress },
    validationLabels: {
      invalidEmail,
      fillInEmail,
      invalidPhoneNumber,
      fillInPhoneNumber,
      invalidDate
    }
  } = useTranslations();

  const form = useZodForm({
    email: z.string()
      .min(1, fillInEmail)
      .email(invalidEmail),
    phoneNumber: z.string()
      .min(8, fillInPhoneNumber)
      .regex(/^(\+)?[0-9-\s]*$/, invalidPhoneNumber),
    birthDate: z.date({ errorMap: () => ({ message: invalidDate }) })
      .max(minDate, invalidDate)
      .nullable()
  }, {
    initialValues: () => (
      {
        email: contactDetailsModel?.email,
        phoneNumber: contactDetailsModel?.phoneNumber,
        birthDate: createDate(contactDetailsModel?.birthDate)
      }
    )
  }) as ZodForm<CustomerDetails>;

  useEffect(() => {
    if (!errors) return;

    // Apply server validations errors to form when possible
    for (const { property, error } of errors.errors) {
      const segments = property.split('.');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      form.setError(camelCase(segments.at(-1)!) as any, { message: error, type: 'validate' });
    }
  }, [errors]);

  const submit = useCallback(() => {
    form.handleSubmit(() => undefined);
    if (!form.formState.isValid) throw new Error("Invalid Form");
    const formData = form.getFormValue();
    return { ...formData, birthDate: formData.birthDate?.toISOString() };
  }, [form]);

  useSubmitEmitter(submitEmitter, submit, form.formState.isValid);

  return (
    <form onSubmit={onSave}>
      <TextInput form={form} type="email" name="email" label={email} autoComplete="email" />
      <TextInput form={form} type="tel" name="phoneNumber" label={phoneNumber} autoComplete="tel" />
      {isBirthDateInputEnabled &&
        <DateInput form={form} name="birthDate" label={birthDate} autoComplete="bday" />
      }

      {active &&
        <Button style={{ marginTop: 16 }} variant="contained" onClick={onSave} disabled={!form.formState.isValid || !valid}>
          <ButtonLoading loading={loading}>
            {goToDeliveryAddress}
          </ButtonLoading>
        </Button>
      }
    </form>
  );
}
