import { FieldValues, Path, useController } from 'react-hook-form';
import { ZodForm } from '../forms/zod-form';
import { KeysOfType } from '@juulsgaard/ts-tools';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { TextField } from '@mui/material';

import { useTranslations } from '../../context/init-data.context';

interface Props<T extends FieldValues> {
  form: ZodForm<T>,
  name: KeysOfType<T, Date|null> & Path<T>,
  label: string,
  placeholder?: string,
  readonly?: boolean,
  autoComplete?: AutoFill|string
  helpText?: string
}

export function DateInput<T extends FieldValues>({
  form,
  name,
  label,
  placeholder,
  autoComplete,
  readonly,
  helpText
}: Props<T>) {

  const fieldInfo = form.getFieldInfo(name);
  const { fieldState, field } = useController({ control: form.control, name });

  const { validationLabels: { optional } } = useTranslations();

  const labelEl = fieldInfo.required ? label : <>{label} <span style={{ marginLeft: "3px" }}>({optional})</span></>;

  const value = useMemo(() => {
    if (!field.value) return '';
    const value: unknown = field.value;
    if (!(value instanceof Date)) return '';
    if (isNaN(value.getTime())) return '';
    const month = (value.getMonth() + 1).toFixed(0).padStart(2, '0');
    const date = value.getDate().toFixed(0).padStart(2, '0');
    return `${value.getFullYear()}-${month}-${date}`;
  }, [field.value]);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    field.onChange(date && !isNaN(date.getTime()) ? date : null);
  }, [field]);

  return (
    <div className="app-input app-date-input">
      <TextField fullWidth type={'date'}
                 label={labelEl} placeholder={placeholder ?? label} autoComplete={autoComplete}
                 error={fieldState.invalid} helperText={fieldState.error?.message ?? helpText}
                 margin="normal" slotProps={{inputLabel: {shrink: true}}}
                 {...field}
                 value={value} onChange={onChange} disabled={readonly}
      />
    </div>
  );

}