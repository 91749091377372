import PageLoader from '../../../../../DesignComponents/Atoms/Loaders/PageLoader/PageLoader';
import Iframe from '../../../../../DesignComponents/Organisms/Iframe/Iframe';
import CheckoutPaymentModel from '../../../../../Models/Checkout/Payment/CheckoutPaymentModel.interface';
import { useEffect, useRef, useState } from 'react';
import { getPaymentStatus } from '../../checkout.requests';
import CheckoutPaymentStatusEnum from '../../../../../Models/Checkout/Payment/CheckoutPaymentStatusEnum.interface';
import { useWindowEvent } from '../../../../../lib/hooks/use-event';
import { isObject, isString } from '@juulsgaard/ts-tools';
import { useHideFooter } from '../../../../../context/ui.context';
import { useHttpClient } from '../../../../../lib/requests/HttpClientProvider';
import { tryParseJson } from '../../../../../lib/util/json';
import { useRequestState } from '../../../../../lib/requests/use-request-state';
import PaymentConfirmViewModel from '../../../../../Models/Checkout/Payment/PaymentConfirmViewModel.interface';
import { useRouter } from '../../../../../lib/router/router-hook';
import { useMounted } from '../../../../../lib/hooks/use-mounted';
import { useAppSettings } from '../../../../../context/init-data.context';

interface Props {
  payment: CheckoutPaymentModel;
  onFailed: (heading: string, message: string) => void;
}

export function PaymentWindow({ payment, onFailed }: Props) {

  const {staticPages: {orderConfirmationPage}} = useAppSettings();
  const router = useRouter();
  useHideFooter();


  const interval = useRef<ReturnType<typeof setInterval>|undefined>();

  const client = useHttpClient();
  useEffect(() => {
    if (!payment.trackingNumber || !payment.paymentGatewayIframeUrl) {
      clearInterval(interval.current);
      return;
    }

    interval.current = setInterval(() => {
      getPaymentStatus(client, payment.trackingNumber).then(
        (response: CheckoutPaymentModel) => {
          switch (response.status) {
            case CheckoutPaymentStatusEnum.Failed:
              onFailed(response.paymentErrorHeading, response.paymentErrorDescription);
              break;

            case CheckoutPaymentStatusEnum.Complete:
              router.navigateByStr(response.redirectUrl);
              break;

            default:
              break;
          }
        }
      );
    }, 5000);

    return () => clearInterval(interval.current);
  }, [payment]);

  const [iFrameHeight, setIFrameHeight] = useState<string|undefined>();
  const [processing, setProcessing] = useRequestState<PaymentConfirmViewModel>();
  const mount = useMounted();

  useWindowEvent('message', e => {
    if (!isString(e.data)) return;

    const data = tryParseJson(e.data);
    if (!isObject(data)) return;
    if (!('egmont' in data)) return;
    if (!isObject(data.egmont)) return;

    Object.entries(data.egmont).forEach(([name, value]) => {
      switch (name) {
        case 'height':
          setIFrameHeight(value + 'px');
          break;
        case 'redirect': {
          if (!isString(value)) return;
          const url = new URL(value);

          const request = client.get(url.pathname + url.search).go<PaymentConfirmViewModel>();
          setProcessing(request);

          request.then(x => {
            if (mount.unmounted) return;
            router.navigate(orderConfirmationPage, {query: {order: x.orderId}});
          });
          break;
        }
      }
    });
  });

  if (processing.loading) {
    return (
      <div style={{flexGrow: 1, alignItems: 'center'}}>
        <PageLoader loading={true} fixed={false} />
      </div>
    );
  }

  return (
    <Iframe height={iFrameHeight} src={payment.paymentGatewayIframeUrl} />
  );
}