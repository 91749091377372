import OrderSummary from '../OrderSummary/OrderSummary';
import { GetCart } from '../../../../Cart/Cart';
import styles from './CheckoutForm.module.scss';
import { useCallback, useMemo, useState } from 'react';
import CheckoutPageModel from '../../../../../Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import CustomerDetailsStep from './CustomerDetails/CustomerDetailsStep';
import { useRequestState } from '../../../../../lib/requests/use-request-state';
import { getValidationError } from '../../../../../lib/requests/request-error';
import DeliveryStep from './Delivery/DeliveryStep';
import { CheckoutSection } from './CheckoutSection/CheckoutSection';
import ConfirmationStep from './Confirmation/ConfirmationStep';
import { SubmitEmitter, useEmitters } from '../../../../../lib/util/submit-emitter';
import UserAgreementsModel from '../../../../../Models/Checkout/CustomerDetails/UserAgreementsModel.interface';
import ContactDetailsModel from '../../../../../Models/Checkout/CustomerDetails/ContactDetailsModel.interface';
import ShippingAndBillingAddressModel
  from '../../../../../Models/Checkout/CustomerDetails/ShippingAndBillingAddressModel.interface';
import { initializePayment, patchCheckoutData } from '../../checkout.requests';
import CheckoutFormViewModel from '../../../../../Models/Checkout/CustomerDetails/CheckoutFormViewModel.interface';
import CheckoutPaymentModel from '../../../../../Models/Checkout/Payment/CheckoutPaymentModel.interface';
import { useSnacks } from '../../../../../lib/snackbars/snackbars';
import { useAppSettings, useTranslations } from '../../../../../context/init-data.context';
import { usePageData } from '../../../../../context/page-data.context';
import { useHttpClient } from '../../../../../lib/requests/HttpClientProvider';

interface Props {
  onPayment: (data: CheckoutPaymentModel) => void;
}

export function CheckoutForm({ onPayment }: Props) {

  const {
    checkoutLabels: {
      contactInformation,
      deliveryAddress,
      termsAndConditionsText
    }
  } = useTranslations();

  const { languageRoute } = useAppSettings();
  const { cart } = GetCart(languageRoute);
  const { checkoutLabels: { checkout } } = useTranslations();
  const { checkoutFormDataModel } = usePageData<CheckoutPageModel>();

  const [state, setState] = useState<CheckoutFormViewModel>(checkoutFormDataModel);
  const step = useMemo(
    () => !state.contactDetailsModel ? 1 : !state.shippingAndBillingAddressModel ? 2 : 3,
    [state]
  );

  const forms = useEmitters(() => (
    {
      agreementsModel: new SubmitEmitter<UserAgreementsModel>(),
      contactDetailsModel: new SubmitEmitter<ContactDetailsModel>(),
      shippingAndBillingAddressModel: new SubmitEmitter<ShippingAndBillingAddressModel>()
    }
  ));

  const snacks = useSnacks();
  const client = useHttpClient();
  const [request, setRequest] = useRequestState<any>();

  const onSave = useCallback(() => {
    if (!forms.valid) return;
    const values = forms.getValue();
    if (!values) return;

    const req = patchCheckoutData(client, values, languageRoute);
    setRequest(req);

    req.then(
      () => setState(x => ({ ...x, ...values })),
      e => snacks.showError(e.message)
    );
  }, [forms]);

  const goToPayment = useCallback(() => {
    if (!forms.valid) return;
    const values = forms.getFullValue();
    if (!values) return;

    const req = initializePayment(client, values, languageRoute);
    setRequest(req);

    req.then(
      onPayment,
      e => snacks.showError(e.message)
    );
  }, [forms]);

  const errors = useMemo(
    () => request.error && getValidationError(request.error),
    [request.error]
  );

  return (
    <div className={styles.checkoutForm}>

      <h1 className={styles.heading}>
        {checkout}
      </h1>

      <section className={styles.cart}>
        {cart && <OrderSummary />}
      </section>

      <div className={styles.form}>

        <CheckoutSection heading={contactInformation} step={1} currentStep={step}>
          <CustomerDetailsStep submitEmitter={forms.emitters.contactDetailsModel} onSave={onSave}
                               valid={forms.valid} loading={request.loading} errors={errors} active={step === 1} />
        </CheckoutSection>

        <CheckoutSection heading={deliveryAddress} step={2} currentStep={step}>
          <DeliveryStep submitEmitter={forms.emitters.shippingAndBillingAddressModel} onSave={onSave}
                        valid={forms.valid} loading={request.loading} errors={errors} active={step === 2} />
        </CheckoutSection>

        <CheckoutSection heading={termsAndConditionsText} step={3} currentStep={step}>
          <ConfirmationStep submitEmitter={forms.emitters.agreementsModel} onSave={goToPayment}
                            valid={forms.valid} loading={request.loading} errors={errors} active={step === 3} />
        </CheckoutSection>

      </div>
    </div>
  );
}